<!--
* @description 演示教学
* @fileName demonstrationTeaching.vue
* @author liulian
* @date 2023/07/24 11:39:29
-->
<template>
  <div class="videoDetail">
    <div class="d_top flex-row-center align-center">
      <div class="d_t_con flex-row-center align-center" id="left_box">
        <div class="d_t_c_left">
          <div
            class="t_c_l_title font-size-24 font-weight-500 font-color-white-ffffff"
          >
            <span>{{ albumTitle }}</span>
            <!-- <span style="margin-left: 10px">{{ nowObj.teachingTitle }}</span> -->
          </div>
          <div class="t_c_l_time font-size-18 font-weight-400">
            <span class="c_l_t_span"
              >{{ nowObj.teachingVideoCount }}人已看</span
            >
            <span class="c_l_t_span">时长：{{ timeLong }}</span>
            <span class="c_l_t_span"
              >视频上传时间：{{ nowObj.updateTime }}</span
            >
          </div>
          <!-- 视频播放 -->
          <div class="t_c_l_video">
            <div
              v-html="nowObj.htmlUrl"
              class="c_l_v_box"
              v-if="nowObj.htmlUrl"
            ></div>
            <video
              class="c_l_v_box"
              muted="muted"
              ref="videoPlayer"
              controls
              autoplay
              @loadedmetadata="getVideoDuration"
              v-else
            >
              <source
                id="video"
                v-if="nowObj.teachingVideoUrl"
                :src="nowObj.teachingVideoUrl"
                type="video/mp4"
              />
            </video>
            <!-- <iframe v-if="nowObj.teachingVideoUrl && nowObj.teachingVideoUrl.indexOf('.mov') === -1" style="display: none" allow="autoplay" :src="nowObj.teachingVideoUrl"></iframe> -->
          </div>
          <img
            v-if="!isexpend"
            class="t_c_l_icon3 cursor-pointer"
            :class="isexpend ? '' : 't_c_l_icon2'"
            src="@/assets/images/userImg/expend_icon@2x.png"
            alt="expend_icon@2x.png"
            @click="expendHadle"
          />
        </div>
        <div
          class="d_t_c_right"
          v-if="isexpend"
          :style="{ height: rightHeight + 'px' }"
        >
          <img
            v-if="isexpend"
            class="t_c_l_icon cursor-pointer"
            :class="isexpend ? '' : 't_c_l_icon2'"
            src="@/assets/images/userImg/expend_icon@2x.png"
            alt="expend_icon@2x.png"
            @click="expendHadle"
          />

          <p class="t_c_r_title font-size-14 font-weight-400">内容简介：</p>
          <p class="t_c_r_text font-size-14 font-weight-400">
            {{ albumContent }}
          </p>
          <p class="t_c_r_title font-size-14 font-weight-400">专辑列表</p>
          <div class="t_c_r_con scroll-style">
            <div
              class="c_r_c_item cursor-pointer"
              :class="item.isActive ? 'c_r_c_item_active' : ''"
              v-for="(item, index) in rightList"
              :key="index"
              @click="clickHandle(item)"
            >
              <div class="c_i_top flex-row-between align-center">
                <div class="c_r_i_title clamp-1 font-size-14 font-weight-400">
                  {{ item.teachingTitle }}
                </div>
                <div class="c_r_i_totalNUm font-size-12 font-weight-400">
                  {{ item.times }}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- 列表 -->
    <div class="d_list">
      <div class="d_l_item">
        <div
          class="l_i_title font-size-24 font-weight-500 font-color-black-1D1D1F"
        >
          {{ albumTitle }}
        </div>
        <div class="l_i_childs flex-row flex-wrap">
          <div
            class="l_i_c_item cursor-pointer"
            v-for="(i, index1) in lists"
            :key="index1"
            @click="clickHandle(i)"
          >
            <img class="l_i_img" :src="i.teachingUrl" alt="i.teachingUrl" />
            <div
              class="l_i_c_title clamp-1 font-size-16 font-weight-500 font-color-black-1D1D1F"
            >
              {{ i.teachingTitle }}
            </div>
            <div
              class="l_i_c_time font-size-14 font-weight-400 flex-row-between align-center"
            >
              <span>{{ i.teachingVideoCount }}</span>
              <span>{{ i.updateTime }}</span>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { listByIdsApi, teachingCountApi } from "@/api/api";
export default {
  name: "videoDetail",
  components: {},
  data() {
    return {
      isexpend: true,
      rightList: [],
      lists: [],
      albumId: null,
      albumTitle: "",
      albumContent: "",
      nowObj: {},
      timeLong: "",
      rightHeight: 588,
    };
  },
  computed: {},
  created() {
    this.albumId = this.$route.query.albumId;
    this.albumTitle = this.$route.query.albumTitle;
    this.getInfo();
  },
  mounted() {
    this.getHeight();
  },
  methods: {
    /*
     * @Description: 获取左侧高度
     * @MethodAuthor:  liulian
     * @Date: 2023-08-09 16:05:56
     * @param: ''
     */
    getHeight() {
      this.rightHeight = 0;
      this.$nextTick(() => {
        // 在这里执行页面已渲染完成后的代码
        let el = document.getElementById("left_box");
        this.rightHeight = el.clientHeight - 40;
      });
    },
    /*
     * @Description: 视频被点击了播放
     * @MethodAuthor:  liulian
     * @Date: 2023-08-01 10:47:17
     * @param: ''
     */
    handleVideoClick() {
      if (this.nowObj.teachingId) {
        teachingCountApi(this.nowObj.teachingId).then((res) => {
          if (res.code === 200) {
          }
        });
      }
    },
    /*
     * @Description: 获取播放视频的时长
     * @MethodAuthor:  liulian
     * @Date: 2023-07-27 14:35:43
     * @param: ''
     */
    getVideoDuration() {
      const videoPlayer = this.$refs.videoPlayer;
      const durationSeconds = Math.floor(videoPlayer.duration);
      const minutes = Math.floor(durationSeconds / 60);
      const seconds = durationSeconds % 60;
      this.timeLong = `${minutes} 分 ${
        seconds < 10 ? "0" + seconds : seconds
      } 秒`;
      console.log("开始播放了昂");
      this.handleVideoClick();
    },
    /*
     * @Description: 点击播放视频
     * @MethodAuthor:  liulian
     * @Date: 2023-07-24 14:55:30
     * @param: ''
     */
    async clickHandle(item) {
      this.timeLong = "0";
      this.rightList.map((i) => {
        this.$set(i, "isActive", item.teachingId === i.teachingId);
      });
      this.nowObj = item;
      // 获取视频文件
      if (item.teachingVideoId) {
        let arr = await this.getVideoUrl(item.teachingVideoId);
        if (arr.length > 0) {
          this.$set(this.nowObj, "teachingVideoUrl", arr[0].url);
        } else {
          this.$set(this.nowObj, "teachingVideoUrl", "");
        }
        this.$refs.videoPlayer.load(); // 加载新视频
      }
      this.getHeight();
    },
    /*
     * @Description: 获取视频
     * @MethodAuthor:  liulian
     * @Date: 2023-08-01 09:43:56
     * @param: ''
     */
    getVideoUrl(ossId) {
      return new Promise((resolve, reject) => {
        listByIdsApi(ossId).then((res) => {
          if (res.code === 200) {
            resolve(res.data);
          }
        });
      });
    },
    /*
     * @Description:获取列表
     * @MethodAuthor:  liulian
     * @Date: 2023-07-24 14:09:08
     * @param: ''
     */
    async getInfo() {
      // let dataList = JSON.parse(sessionStorage.getItem("detailItem"))
      // 获取URL参数
      const urlParams = new URLSearchParams(window.location.search);
      // 解码数据
      const encodedData = urlParams.get("data");
      const dataList = JSON.parse(encodedData);
      this.lists = dataList && dataList.teachingVoList;
      this.albumContent = dataList.albumContent;
      this.rightList = dataList && dataList.teachingVoList;

      if (this.rightList.length > 0) {
        this.$set(this.rightList[0], "isActive", true);
        this.nowObj = this.rightList[0];
        // 获取视频文件
        if (this.nowObj.teachingVideoId) {
          let arr = await this.getVideoUrl(this.nowObj.teachingVideoId);
          if (arr) {
            this.$set(this.nowObj, "teachingVideoUrl", arr[0].url);
          }
          this.$refs.videoPlayer.load(); // 加载新视频
          this.$refs.videoPlayer.play(); // 自动播放

          // 时长秒单位转分秒
          this.rightList.map((i) => {
            const durationSeconds = Math.floor(i.teachingVideoTime);
            const minutes = Math.floor(durationSeconds / 60);
            const seconds = durationSeconds % 60;
            let times = `${minutes} ：${
              seconds < 10 ? "0" + seconds : seconds
            }`;
            this.$set(i, "times", times);
          });
        } else {
          if (this.nowVideo.htmlUrl) {
            let url = this.nowVideo.htmlUrl.replace(
              "<iframe",
              '<iframe height="100%" width="100%"'
            );
            this.$set(this.nowVideo, "htmlUrl", url);
          }
        }
      }
    },
    /*
     * @Description: 展开和收起右侧内容
     * @MethodAuthor:  liulian
     * @Date: 2023-07-24 14:08:54
     * @param: ''
     */
    expendHadle() {
      this.isexpend = !this.isexpend;
    },
  },

  watch: {
    "$i18n.locale": function () {
      this.getInfo();
    },
  },
};
</script>

<style scoped lang="scss">
.videoDetail {
  min-height: 50vh;
  .d_top {
    min-height: 760px;
    background: #1d1d1f;
    .d_t_con {
      width: 1200px;
      min-height: 640px;
      background: #111417;
      color: rgba(255, 255, 255, 0.8);
      padding-left: 20px;
      padding-right: 20px;
      .d_t_c_left {
        flex: 1;
        height: 100%;
        position: relative;
        padding-bottom: 20px;
        .t_c_l_title {
          // margin-top: 24px;
          // min-height: 24px;
          // line-height: 24px;
          // width: 80%;
          padding-right: 45px;
          padding-top: 28px;
        }
        .t_c_l_time {
          margin-top: 17px;
          height: 24px;
          line-height: 24px;
          .c_l_t_span {
            margin-right: 24px;
          }
          .c_l_t_span:last-child {
            margin-right: unset;
          }
        }
        .t_c_l_video {
          height: 506px;
          width: 100%;
          background: #d8d8d8;
          margin-top: 16px;
          .c_l_v_box {
            width: 100%;
            height: 100%;
          }
        }
      }
      .t_c_l_icon {
        position: absolute;
        top: 0;
        left: -48px;
        width: 48px;
        height: 48px;
      }
      .t_c_l_icon3 {
        position: absolute;
        top: 28px;
        right: 0;
        width: 48px;
        height: 48px;
      }
      .d_t_c_right {
        width: 260px;
        height: 100%;
        background: #2f3235;
        // margin-top: -4px;
        position: relative;

        display: flex;
        flex-direction: column;
        .t_c_r_title {
          margin-top: 16px;
          height: 20px;
          padding-left: 12px;
          padding-right: 12px;
        }
        .t_c_r_text {
          margin-top: 16px;
          text-align: justify;
          padding-left: 12px;
          padding-right: 12px;
        }

        .t_c_r_con {
          flex: 1;
          margin-top: 14px;
          display: flex;
          flex-direction: column;
          padding-bottom: 17px;
          overflow: hidden;
          overflow-y: auto;

          .c_r_c_item {
            height: 48px;
            .c_i_top {
              height: 48px;
              padding-left: 12px;
              padding-right: 12px;
            }

            .r_c_i_img {
              width: 120px;
              height: 68px;
              border-radius: 4px;
              margin-right: 6px;
              margin-top: 14px;
            }
            .c_r_i_title {
              height: 20px;
              width: 140px;
            }

            .c_i_bottom {
              margin-top: 10px;
              padding-bottom: 10px;
              .c_i_icon {
                width: 12px;
                height: 12px;
                object-fit: cover;
                margin-left: 2px;
              }
              .c_i_childs {
                padding-left: 6px;
                padding-right: 12px;
                .c_i_c_item {
                  margin-bottom: 8px;
                }
              }
            }
          }
          .c_r_c_item_active {
            background: rgba(29, 29, 31, 0.4);
            .r_c_i_img {
              margin-top: 6px;
            }
          }
        }
        .scroll-style {
          width: 100%;
          height: 150px;
          overflow: auto;
        }
        .scroll-style::-webkit-scrollbar {
          width: 0px;
          background: white;
          overflow: hidden;
        }
        .scroll-style::-webkit-scrollbar-corner,
        .scroll-style::-webkit-scrollbar-thumb,
        .scroll-style::-webkit-scrollbar-track {
          border-radius: 4px;
        }
        .scroll-style::-webkit-scrollbar-corner,
        .scroll-style::-webkit-scrollbar-track {
          background-color: rgba(180, 160, 124, 0.5);
          box-shadow: inset 0 0 1px rgba(6, 228, 106, 0.5);
        }
        .scroll-style::-webkit-scrollbar-thumb {
          background-color: yellow;
        }
      }
    }
  }
  .d_list {
    width: 1200px;
    margin: 32px auto;
    .d_l_item {
      border-bottom: 1px solid #d8d8d8;
      margin-bottom: 64px;
      padding-bottom: 48px;
      .l_i_title {
        margin-bottom: 32px;
      }
    }
    .d_l_item:last-child {
      border-bottom: unset;
    }
    .l_i_childs {
      gap: 27px;
      .l_i_c_item {
        flex: 0 0 23.3%;
        .l_i_img {
          width: 100%;
          height: 158px;
        }
        .l_i_c_title {
          margin-top: 8px;
          height: 24px;
        }
        .l_i_c_time {
          color: rgba(29, 29, 31, 0.6);
          height: 14px;
          margin-top: 8px;
        }
      }
    }
  }
}
</style>
